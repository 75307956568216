import React from "react"

/**
 * Change the content to add your own bio
 */

export default function Bio() {
  return (
    <>
      Software Engineering Tutorials & Best Practices
    </>
  )
}
